import React from 'react';
import { useTranslation } from 'react-i18next';

import { Layout } from '../../components/layout/layout';
import { AuthPageHeader } from '../../components/auth-page-header/auth-page-header';
import { ErrorBlock } from '../../components/error/error';

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <AuthPageHeader />
      <ErrorBlock
        title={`${t('error.title')} 404`}
        message={t('error.notFound')}
      />
    </Layout>
  );
};
