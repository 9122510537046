import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isError } from '@sentry/utils';

import { Layout } from '../layout/layout';
import { AuthPageHeader } from '../auth-page-header/auth-page-header';
import { ErrorBlock } from '../error/error';

const isNotFound = (error: unknown) =>
  isRouteErrorResponse(error) && error.status === 404;

export const ErrorBoundary = () => {
  const error = useRouteError();
  const { t } = useTranslation();

  return (
    <Layout>
      <AuthPageHeader />
      <ErrorBlock
        title={t('error.title')}
        message={isNotFound(error) ? t('error.notFound') : t('error.common')}
        errorMessage={isError(error) ? error.message : undefined}
      />
    </Layout>
  );
};
