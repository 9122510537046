import React from 'react';

export const InstagramIcon = () => (
  <i>
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#fff">
        <path d="M8.02709 3.85156c-2.26573 0-4.14306 1.84496-4.14306 4.14306 0 2.29808 1.84496 4.14308 4.14306 4.14308 2.29811 0 4.14301-1.8773 4.14301-4.14308 0-2.26573-1.8773-4.14306-4.14301-4.14306Zm0 6.79724c-1.45654 0-2.65415-1.19764-2.65415-2.65418 0-1.45654 1.19761-2.65415 2.65415-2.65415s2.65411 1.19761 2.65411 2.65415-1.19757 2.65418-2.65411 2.65418ZM12.3322 4.69324c.5184 0 .9387-.42025.9387-.93866s-.4203-.93866-.9387-.93866-.9386.42025-.9386.93866.4202.93866.9386.93866Z" />
        <path d="M14.7596 1.32707C13.9181.453147 12.7205 0 11.361 0H4.69331C1.87732 0 0 1.87732 0 4.69331v6.63539c0 1.3918.453147 2.5894 1.35944 3.4633.87393.8416 2.03916 1.2623 3.36624 1.2623h6.60302c1.3918 0 2.557-.4531 3.3986-1.2623.8739-.8415 1.327-2.0392 1.327-3.431V4.69331c0-1.35944-.4531-2.52468-1.2947-3.36624ZM14.6302 11.361c0 1.0034-.3561 1.8126-.9387 2.3629-.5826.5502-1.3918.8415-2.3628.8415H4.72568c-.97103 0-1.78023-.2913-2.36284-.8415-.58262-.5826-.87393-1.3918-.87393-2.3952V4.69331c0-.97103.29131-1.78022.87393-2.36284.55025-.55025 1.39181-.84156 2.36284-.84156h6.66772c.971 0 1.7802.29131 2.3628.87393.5503.58261.874 1.39181.874 2.33047V11.361Z" />
      </g>
    </svg>
  </i>
);
