import React, { FC } from 'react';
import cn from 'classnames';

import styles from './spinner.module.css';

type Props = {
  className?: string;
};

export const Spinner: FC<Props> = ({ className }) => (
  <div className={cn(styles.spinner, className)} role="status" />
);
