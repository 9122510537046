import { CONFIG_FILE } from '../constants';
import { Config } from '../types/config';

export const getAppConfigFromFile = (): Config => {
  return {
    ...CONFIG_FILE,
    API_URL: CONFIG_FILE.API_URL,
    WEBSOCKET_API_URL: CONFIG_FILE.WEBSOCKET_API_URL,
    FOOTER_SOCIALS: CONFIG_FILE.FOOTER_SOCIALS === 'true',
    AUDIO_CHAT: CONFIG_FILE.AUDIO_CHAT === 'true',
    FAQ: CONFIG_FILE.FAQ === 'true',
    HAS_BREAK: CONFIG_FILE.HAS_BREAK === 'true',
    TELEGRAM_SUPPORT_LINK: CONFIG_FILE.TELEGRAM_SUPPORT_LINK,
    PRIORITIZE_MY_TEAM: CONFIG_FILE.PRIORITIZE_MY_TEAM === 'true',
    RESULTS_LIMIT: CONFIG_FILE?.RESULTS_LIMIT
      ? parseInt(CONFIG_FILE.RESULTS_LIMIT)
      : undefined,
  };
};
