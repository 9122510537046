export const saveName = (name: string) =>
  localStorage.setItem('quiz_heroes_name', name);

export const loadName = () => localStorage.getItem('quiz_heroes_name');

export const setStorageItem = (key: string, value: string) => {
  return new Promise((resolve, reject) => {
    try {
      localStorage.setItem(key, value);
      resolve(value);
    } catch (e) {
      reject(new Error('Не удалось сохранить сессию'));
    }
  });
};

export const getStorageItem = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    return null;
  }
};

export function getJsonStorageItem<T>(key: string): T | null {
  try {
    const rawValue = getStorageItem(key);
    const parsedValue = JSON.parse(rawValue || '');
    return parsedValue;
  } catch (error) {
    return null;
  }
}
