import axios from 'axios';

import { FeedbackStars } from '../types/feedback';
import { SignUpData } from '../types/sign-up';
import { GameData } from '../types/game-data';
import { Check } from '../types/check';
import { TeamResponse } from '../types/team';
import { Question } from '../types/question';
import { ResultsRecord } from '../types/results';
import { AutomaticVideoData } from '../types/automatic-video';

type Response<T> = T & {
  status?: 'error' | 'success';
  header?: string;
  reason?: string;
};

export class ApiService {
  transport;

  constructor(baseURL: string) {
    this.transport = axios.create({
      baseURL,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    });
  }

  connect = (token: string, name: string, user_token?: string) =>
    this.transport.get<Response<GameData>>('/connect', {
      params: {
        token,
        name,
        user_token,
      },
    });

  check = (token: string) =>
    this.transport.get<Response<Check>>('/check', {
      params: {
        token,
      },
    });

  getQuestion = (token: string) =>
    this.transport.get<Question>('/get_question', {
      params: {
        token,
      },
    });

  results = (token: string) =>
    this.transport.get<ResultsRecord[]>('/results', {
      params: {
        token,
      },
    });

  makeCaptain = (token: string, user_token: string) =>
    this.transport.get<Response<object>>('/make_captain', {
      params: {
        token,
        user_token,
      },
    });

  submitAnswer = (
    token: string,
    type: number,
    value: string,
    id?: string,
    stake?: number
  ) =>
    this.transport.get<Response<object>>('/submit_answer', {
      params: {
        token,
        type,
        value,
        id,
        stake,
      },
    });

  submitButtons = (token: string, type: number, value: string, id?: string) =>
    this.transport.get<Response<object>>('/submit_button', {
      params: {
        token,
        type,
        value,
        id,
      },
    });

  submitInteractive = (
    token: string,
    type: number,
    value: string,
    id?: string
  ) =>
    this.transport.get<Response<object>>('/interactive_action', {
      params: {
        token,
        type,
        value,
        id,
      },
    });

  submitFeedback = (
    token: string,
    user_token: string,
    stars: FeedbackStars,
    text: string
  ) =>
    this.transport.post<Response<object>>('/feedback', {
      token,
      user_token,
      stars,
      text,
    });

  team = (token: string) =>
    this.transport.get<Response<TeamResponse>>('/team', {
      params: {
        token,
      },
    });

  signUp = (data: SignUpData) => {
    return this.transport.post<Response<{ url: string }>>('/reg', data);
  };

  checkAutomaticVideo = (token: string) => {
    return this.transport.get<Response<AutomaticVideoData>>(
      '/check_automatic_video',
      {
        params: {
          token,
        },
      }
    );
  };
}
