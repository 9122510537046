import React from 'react';

export const TelegramIcon = ({ width = 23, height = 18 }) => (
  <i>
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2359 18c-.1703.0013-.3367-.0498-.4763-.1462l-7.89214-5.4724c-.09764-.068-.17908-.1563-.23865-.2587-.05958-.1024-.09584-.2163-.10627-.334-.01107-.1189.00454-.2388.04571-.3511.04116-.1122.10688-.2142.19245-.2984l2.1516-2.15978-3.68732 2.28148 1.64248 3.7268.72264-.7145c.1536-.1529.3623-.2392.5802-.24.2179-.0007.4273.0841.5819.236.1546.1518.2419.3581.2427.5736.0008.2154-.085.4223-.2386.5752l-1.6425 1.5589c-.0947.0946-.2114.1647-.33993.2042-.12852.0395-.2649.0471-.39712.0223-.13221-.0249-.25623-.0815-.36112-.1649-.10489-.0833-.18746-.1909-.24042-.3133l-2.37338-5.4724c-.07597-.1789-.08462-.3788-.02436-.5635.06027-.1847.18544-.342.35286-.4433L17.0122 3.98604c.1715-.10435.3758-.14278.574-.10799.1983.0348.3767.14039.5014.29672.1247.15633.1869.3525.1749.55122-.0121.19872-.0975.38615-.2402.52666l-6.422 6.34935 6.11 4.2382 3.2849-13.8028L3.11689 8.61405l1.97918.81193c.10625.03527.20408.09166.28746.16572.08338.07405.15054.16419.19735.26488.04682.10068.07229.20982.07485.32052.00256.1108-.01784.2209-.05995.3236-.04211.1027-.10503.1958-.1849.2736-.07987.0777-.17499.1385-.2795.1785-.10452.04-.21621.0585-.32821.0542-.112-.0042-.22193-.0311-.32303-.0789L.529988 9.34479c-.155815-.05843-.289963-.16235-.384626-.29794C.0506985 8.91125 0 8.75039 0 8.58563c0-.16476.0506985-.32562.145362-.46121.094663-.1356.228811-.23951.384626-.29794L21.8986.0481719c.1383-.04932974.2875-.0610377.4319-.0339016.1445.0271362.2789.0921217.3893.1881687.1142.098861.1984.22707.2434.370395.0449.143325.0488.296159.0112.441536L19.0325 17.3748c-.0304.1301-.0928.2507-.1816.3513-.0888.1005-.2013.1779-.3276.2252-.0924.0319-.1895.0484-.2874.0487Z"
        fill="#fff"
      />
    </svg>
  </i>
);
