export type Question = {
  type: QuestionType;
  text: string;
  id: string;
  disabled?: boolean;
  title?: string;
  value?: string;
  stake?: number;
  buttons?: QuestionButton[];
};

export type QuestionButton = {
  text: string;
  value: string;
};

export type Answer = {
  text?: string | null;
  stake?: number | null;
};

export enum QuestionType {
  Stub = 0,
  Text = 1,
  Options = 2,
  TextWithRate = 3,
  OptionsInteractive = 4,
  Feedback = 5,
}

export type UpdateQuestionType = { delay?: number; autosubmit?: boolean };
