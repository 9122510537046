import i18n from 'i18next';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LOCALE } from '../constants';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    fallbackLng: 'en',
    lng: DEFAULT_LOCALE,
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', lng => {
  document.documentElement.setAttribute('lang', lng);
});

export { i18n };
