import React, { lazy } from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';

import { useAppStore } from '../store/app';
import { QH } from '../constants';
import { AppStates } from '../types/app-states';
import { Layout } from '../components/layout/layout';

const AuthPage = lazy(() => import('./auth/auth'));
const ErrorPage = lazy(() => import('./error/error'));
const QuizPage = lazy(() => import('./quiz/quiz'));
const QuizPageWhitelabel = lazy(() =>
  import('./quiz/whitelabel/quiz-whitelabel')
);

export const Root = observer(() => {
  const { gameData, state, layout } = useAppStore();
  const title = gameData?.name ? `${QH} — ${gameData?.name}` : QH;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {state === AppStates.Initial && <Layout fetching showFooter={false} />}
      {state === AppStates.Error && <ErrorPage />}
      {state === AppStates.SignedIn &&
        (layout === 'quizheroes' ? <QuizPage /> : <QuizPageWhitelabel />)}
      {state === AppStates.Checked && <AuthPage />}
    </>
  );
});

Root.displayName = 'Root';
