import { observable, action, computed } from 'mobx';

import { ResultsRecord } from '../types/results';
import { AppStore } from './app';
import { publishSentryMessage } from '../configs/sentry/sentry-utils';

export class ResultsStore {
  initialData = [];

  @observable fetching = false;
  @observable data: ResultsRecord[] = this.initialData;

  constructor(private appStore: AppStore, private resultsLimit = Infinity) {}

  @computed
  get token() {
    if (!this.appStore.token) {
      throw new Error('Token is required');
    }

    return this.appStore.token;
  }

  @computed
  get results(): ResultsRecord[] {
    if (!this.data.length) {
      return this.data;
    }

    if (this.appStore.prioritizeMyTeam) {
      const myTeam = this.data.find(
        item => item.name === this.appStore.gameData?.team_name
      );
      const myTeamPosition = myTeam?.position;

      if (!myTeam) {
        publishSentryMessage('My team does not exist in the list.');
      } else if ((myTeamPosition || 0) > this.resultsLimit) {
        return [
          ...this.data
            .sort((a, b) => Number(a.position) - Number(b.position))
            .slice(0, this.resultsLimit),
          myTeam,
        ];
      }
    }

    return this.data.sort((a, b) => Number(a.position) - Number(b.position));
  }

  init = () => {
    this.listenSocket();
    this.fetch();
  };

  fetch = async () => {
    this.setFetching(true);
    try {
      const response = await this.appStore.api.results(this.token);
      this.setData(response.data);
    } catch (error) {
      // TODO: push error notification, add retry
      console.log(error);
    } finally {
      this.setFetching(false);
    }
  };

  listenSocket = () => {
    this.appStore.socket.on('updateResults', () => {
      // TODO: get data from socket
      this.fetch();
    });

    this.appStore.socket.io.on('reconnect', this.fetch);
  };

  @action
  setData(data: ResultsRecord[]) {
    this.data = data;
  }

  @action
  setFetching(value: boolean) {
    this.fetching = value;
  }
}
