import { AxiosResponse } from 'axios';
import { observable, action, when, computed, reaction } from 'mobx';

import { API_STATES } from '../constants';
import { FeedbackStars } from '../types/feedback';
import {
  Answer,
  Question,
  QuestionButton,
  QuestionType,
  UpdateQuestionType,
} from '../types/question';
import { AppStore } from './app';
import { wait } from '../utils/wait';
import { i18n } from '../configs/i18n';

const transformButtons = (buttons: any): QuestionButton[] =>
  buttons.map(({ callback_data, text }: any) => ({
    text,
    value: callback_data,
  }));

export class QuestionStore {
  initialData: Question = {
    text: 'Ожидайте начала игры',
    type: 0,
    id: '',
  };

  @observable fetching = false;
  @observable data = this.initialData;
  @observable message: string | null = null;
  @observable error: string | null = null;
  @observable answer: Answer | null = null;
  @observable ignoreFetch = false;
  @observable delay = 0;
  @observable isSubmitted = false;

  constructor(private appStore: AppStore) {}

  @computed
  get type() {
    return this.data.type;
  }

  @computed
  get token() {
    if (!this.appStore.token) {
      throw new Error('Token is required');
    }

    return this.appStore.token;
  }

  fetch = async () => {
    const startedAt = Date.now();
    const { data } = await this.appStore.api.getQuestion(this.token);
    const finishedAt = Date.now();
    const fetchingTime = finishedAt - startedAt;
    const minFetchingTime = 150;

    // TOOD: move animation duration to question component
    if (fetchingTime < minFetchingTime) {
      await wait(minFetchingTime - fetchingTime);
    }

    if (data.id !== this.data.id) {
      this.setAnswer(null);
      this.setMessage(null);
    }

    this.setData(data);
  };

  loadQuestion = async () => {
    try {
      this.setFetching(true);
      this.setError(null);
      await this.fetch();
    } catch (error) {
      this.setError(i18n.t('question.error.fetch'));
    } finally {
      this.setFetching(false);
    }
  };

  finishQuestion = async () => {
    try {
      this.setFetching(true);

      if (this.appStore.isCaptain && !this.data.disabled && !this.isSubmitted) {
        switch (this.type) {
          case QuestionType.Text:
            if (this.answer?.text) {
              await this.submitTextAnswer(this.answer.text, this.data.id);
            }
            break;
          case QuestionType.TextWithRate:
            if (this.answer?.text && this.answer.stake !== null) {
              await this.submitRateAnswer(
                Number(this.answer.stake),
                this.answer.text,
                this.data.id
              );
            }
            break;
        }
      }

      await this.fetch();
    } catch (error) {
      console.log(error);
      this.setError(i18n.t('question.error.fetch'));
    } finally {
      this.setFetching(false);
    }
  };

  @action
  setData(data: Question) {
    const result = data;
    // это ненужно, если контракт будет правильный
    if (data.type === QuestionType.OptionsInteractive) {
      result.buttons = transformButtons(data.buttons);
    }

    this.data = result;
  }

  @action
  setFetching(value: boolean) {
    this.fetching = value;
  }

  @action
  setError(value: string | null) {
    this.error = value;
  }

  @action
  setMessage(value: string | null) {
    this.message = value;
  }

  @action
  setAnswer = (value: Answer | null) => {
    this.answer = value
      ? {
          ...this.answer,
          ...value,
        }
      : value;
  };

  @action
  setSubmitted = (value: boolean) => (this.isSubmitted = value);

  @action
  setIgnoreFetch(value: boolean) {
    this.ignoreFetch = value;
  }

  @action.bound
  startSubmitFlow(isOnlyForCaptain = true) {
    return new Promise<null>((resolve, reject) => {
      this.restoreInitialState();

      if (isOnlyForCaptain && !this.appStore.isCaptain) {
        reject(new Error(i18n.t('question.error.captain')));
      }

      this.setFetching(true);
      resolve(null);
    });
  }

  @action.bound
  handleSubmitResponse(response: AxiosResponse) {
    const {
      data: { status, reason, text },
    } = response;

    if (status === API_STATES.ERROR) {
      throw new Error(reason);
    }

    this.setMessage(text || i18n.t('question.success'));
    this.setSubmitted(true);
  }

  @action.bound
  handleInteractiveSubmitResponse(response: AxiosResponse) {
    const {
      data: { status, reason, text },
    } = response;

    if (status === API_STATES.ERROR) {
      throw new Error(reason);
    }

    if (status === API_STATES.SUCCESS) {
      this.setSubmitted(true);
      this.setMessage(text);
    }
  }

  @action.bound
  catchSubmitError(error: Error) {
    this.setError(error ? error.message : i18n.t('question.error.unknown'));
  }

  @action.bound
  finishSubmitFlow() {
    this.setFetching(false);
  }

  submitTextAnswer = (value: string, id?: string) => {
    return this.startSubmitFlow()
      .then(() =>
        this.appStore.api.submitAnswer(this.token, this.data.type, value, id)
      )
      .then(this.handleSubmitResponse)
      .catch(this.catchSubmitError)
      .finally(this.finishSubmitFlow);
  };

  submitButtonsAnswer = (value: string) => {
    return this.startSubmitFlow()
      .then(() =>
        this.appStore.api.submitButtons(this.token, this.data.type, value)
      )
      .then(this.handleSubmitResponse)
      .catch(this.catchSubmitError)
      .finally(this.finishSubmitFlow);
  };

  submitInteractiveAnswer = (value: string) => {
    return this.startSubmitFlow()
      .then(() =>
        this.appStore.api.submitInteractive(this.token, this.data.type, value)
      )
      .then(response => {
        this.handleInteractiveSubmitResponse(response);

        // TODO: нужно ли тут это?
        // const {
        //   data: { question },
        // } = response;

        // if (question) {
        //   this.setIgnoreFetch(true);
        //   this.setData(question);
        //   this.setIgnoreFetch(false);
        // }
      })
      .catch(this.catchSubmitError)
      .finally(this.finishSubmitFlow);
  };

  submitRateAnswer = (stake: number | null, text: string, id?: string) => {
    if (stake === null) {
      this.setError(i18n.t('question.error.rate'));
      return null;
    }

    return this.startSubmitFlow()
      .then(() =>
        this.appStore.api.submitAnswer(
          this.token,
          this.data.type,
          text,
          id,
          stake
        )
      )
      .then(this.handleSubmitResponse)
      .catch(this.catchSubmitError)
      .finally(this.finishSubmitFlow);
  };

  submitFeedback = (stars: FeedbackStars, text: string) => {
    return this.startSubmitFlow(false)
      .then(() =>
        this.appStore.api.submitFeedback(
          this.token,
          this.appStore.gameData?.user_token || '',
          stars,
          text
        )
      )
      .then(this.handleSubmitResponse)
      .catch(this.catchSubmitError)
      .finally(this.finishSubmitFlow);
  };

  handleUpdateQuestion = (response: UpdateQuestionType) => {
    const { delay, autosubmit } = response || {};
    if (!this.ignoreFetch) {
      if (autosubmit) {
        if (delay) {
          this.setDelay(delay);
          when(() => this.delay === 0, this.finishQuestion);
        } else {
          this.finishQuestion();
        }
      } else {
        if (delay) {
          this.setDelay(delay);
          when(() => this.delay === 0, this.loadQuestion);
        } else {
          this.loadQuestion();
        }
      }
    }
  };

  updateInputText = ({ text, stake }: Answer) => {
    if (!this.appStore.isCaptain) {
      return;
    }

    this.appStore.socket.emit('updateAnswerText', {
      text,
      stake,
      token: this.appStore.token,
    });
  };

  restoreInitialState() {
    this.setError(null);
    this.setMessage(null);
    this.setSubmitted(false);
  }

  cleanUp = () => {
    this.appStore.socket.off('updateAnswerText');
    this.appStore.socket.off('updateQuestion');
    this.appStore.socket.io.off('reconnect', this.fetch);
  };

  start = () => {
    this.loadQuestion();

    if (!this.appStore.isCaptain) {
      this.appStore.socket.on('updateAnswerText', this.setAnswer);
    }
    this.appStore.socket.on('updateQuestion', this.handleUpdateQuestion);

    this.appStore.socket.io.on('reconnect', this.fetch);

    reaction(
      () => this.appStore.isCaptain,
      isCaptain => {
        if (isCaptain) {
          this.appStore.socket.off('updateAnswerText');
        } else {
          this.appStore.socket.on('updateAnswerText', this.setAnswer);
        }
      }
    );
  };

  @action
  setDelay(value: number) {
    this.delay = value;
  }

  clearDelay = () => {
    this.setDelay(0);
  };
}
