import { parseUrl, stringifyUrl } from 'query-string';

export const updateUrl = (nextToken: string) => {
  const { url, query } = parseUrl(window.location.href);
  const nextUrl = stringifyUrl({
    url,
    query: {
      ...query,
      token: nextToken,
    },
  });
  window.history.pushState({ path: nextUrl }, '', nextUrl);
};
