import React from 'react';

import { Spinner } from '../../ui/spinner/spinner';

import styles from './layout-stub.module.css';

export const LayoutStub = () => {
  return (
    <div className={styles.layout}>
      <Spinner />
    </div>
  );
};
