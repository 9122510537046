import { captureException, captureMessage, SeverityLevel } from '@sentry/react';

export const publishError = (error: Error) => {
  captureException(error);
};

export const publishSentryMessage = (
  message: string,
  options?: {
    level?: SeverityLevel;
    extra?: Record<string, unknown>;
  }
) => {
  captureMessage(message, {
    level: options?.level || 'info',
    extra: options?.extra,
  });
};
