import * as Sentry from '@sentry/react';

import { isTestEnv } from '../../constants';

export const sentryConfig: Sentry.BrowserOptions = {
  environment: isTestEnv() ? 'test' : 'production',
  dsn:
    'https://83db9d64c5e543bd96cf817896509f5a@o4505283539763200.ingest.sentry.io/4505283604381696',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost'],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    '__ybro.readability.runDetection',
    'window.webkit.messageHandlers',
    '__gCrWeb.videoStatistics.closeSession',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    "Can't find variable: __gCrWeb",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Network errors such as going offline or being blocked by a proxy
    'Failed to fetch',
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'can\'t redefine non-configurable property "userAgent"',
    "Can't find variable: _AutofillCallbackHandler",
    // see https://github.com/RecordReplay/devtools/issues/3618
    'instantSearchSDKJSBridgeClearHighlight',
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
    'ResizeObserver loop limit exceeded',
    // Вызов abort у fetch - штатная ситуация, например при отмонтировании компонента, который что-то грузит в эффекте
    // https://sentry.m2.ru/organizations/sentry/issues/212081/?project=2
    'canceled',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Protocols
    /^file:\/\//i,
    // Chrome extensions
    /extensions\//i,
    /^chrome(-extension)?:\/\//i,
    // Safari extensions
    /safari-(web-)?extension:/i,
    // Blinger
    /widget\.blinger\.io\/widget\/blinger-widget\.js/i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /localhost/i,
  ],
  normalizeDepth: 5,
  attachStacktrace: true,
};
