import React, { useMemo, useEffect, Suspense } from 'react';
import { configure } from 'mobx';
import queryString from 'query-string';
import { RouterProvider } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'bootstrap/dist/css/bootstrap-reboot.min.css';
import './styles/index.css';

import { AppStoreContext, AppStore } from './store/app';
import { router } from './router/router';
import { Layout } from './components/layout/layout';

configure({ enforceActions: 'observed' });

const parsed = queryString.parse(window.location.search);
const { token, hideVideo, name } = parsed;

export const App = () => {
  const config = {
    token: typeof token === 'string' ? token : null,
    hideVideo: Boolean(hideVideo),
    name: typeof name === 'string' ? name : null,
  };
  const store = useMemo(() => new AppStore(config), []);

  useEffect(() => {
    store.init();
    return () => {
      store.cleanUp();
    };
  }, [store]);
  return (
    <AppStoreContext.Provider value={store}>
      <Suspense fallback={<Layout fetching />}>
        <RouterProvider router={router} />
      </Suspense>
    </AppStoreContext.Provider>
  );
};
