import { observable, action, computed, toJS } from 'mobx';

import { Score } from '../types/score';
import { AppStore } from './app';
import { TeamRecord } from '../types/team';
import {
  publishError,
  publishSentryMessage,
} from '../configs/sentry/sentry-utils';

export class TeamStore {
  initialData: TeamRecord[] = [];

  @observable fetching = false;
  @observable data = this.initialData;
  @observable score: Score;

  constructor(private appStore: AppStore) {}

  @computed
  get isCaptain() {
    const me = this.data.find(
      ({ user_token }) => user_token === this.appStore.gameData?.user_token
    );
    return Boolean(me?.is_captain);
  }

  @computed
  get token() {
    if (!this.appStore.token) {
      throw new Error('Token is required');
    }

    return this.appStore.token;
  }

  @computed
  get activeUsers() {
    const activeUsers = this.data.filter(item => Boolean(item.is_active));

    if (this.data.length > 0 && !activeUsers.length) {
      publishSentryMessage('Active users are empty.', {
        extra: {
          data: toJS(this.data),
        },
      });
    }

    return activeUsers;
  }

  init = () => {
    this.listenSocket();
    this.fetch();
  };

  fetch = async () => {
    try {
      this.setFetching(true);
      const {
        data: { status, reason, team },
      } = await this.appStore.api.team(this.token);
      if (status === 'error') {
        throw new Error(reason);
      }

      this.setData(team);
    } catch (error) {
      publishError(error);
    } finally {
      this.setFetching(false);
    }
  };

  listenSocket = () => {
    this.appStore.socket.on('teamStat', data => {
      this.setScore(data);
    });
    this.appStore.socket.on('updateTeam', data => {
      this.setData(data.team);
    });
    this.appStore.socket.io.on('reconnect', this.fetch);
  };

  @action
  setData(data: TeamRecord[]) {
    this.data = data;
  }

  @action
  setScore(data: Score) {
    this.score = data;
  }

  @action
  setFetching(value: boolean) {
    this.fetching = value;
  }
}
