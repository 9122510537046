import React from 'react';

export const VkIcon = () => (
  <i>
    <svg
      width="20"
      height="11"
      viewBox="0 0 20 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2152.48325h-3.2455c-.1324-.000001-.2624.034812-.3761.100733-.1137.065921-.2068.160484-.2694.273625 0 0-1.2945 2.310752-1.7102 3.089052-1.1186 2.09374-1.8429 1.43644-1.8429.46472v-3.3505c0-.279981-.1148-.548493-.319-.746468-.2043-.197975-.4813-.30919596-.7701-.30919596H8.24183C7.90952-.0187338 7.57641.0400622 7.27414.17602c-.30228.135957-.56367.344562-.75937.606001 0 0 1.23908-.194196 1.23908 1.424139 0 .4013.02133 1.55574.04076 2.52402.00366.14269-.03793.28303-.11916.40208-.08123.11904-.19819.21106-.33509.26363-.1369.05257-.28714.06316-.43044.03033-.1433-.03282-.27276-.10748-.3709-.21388-.9988-1.34654-1.82614-2.8051-2.46404-4.343986C4.0202.753237 3.9325.655767 3.82229.587508 3.71208.519249 3.58398.483066 3.45319.48325H.504463C.423353.482715.343323.501275.271246.537335.199169.573396.137201.625878.0906655.690275.0441302.754671.0144189.829056.004084.907038c-.01033486.077982-.00098426.157232.0272485.230942C.926709 3.51864 4.7837 11 9.18608 11H11.039c.1942 0 .3804-.0748.5177-.2078.1372-.1331.2144-.3136.2144-.5018V9.20586c0-.13605.041-.26914.1181-.38285.0771-.11371.1868-.20308.3156-.25709.1288-.05402.2711-.07033.4094-.04691.1383.02342.2665.08554.3687.1787l2.2179 2.01989c.1992.1814.4622.2824.7355.2824h2.9125c1.4046 0 1.4046-.9448.6388-1.67593-.539-.51453-2.4839-2.50168-2.4839-2.50168-.1666-.16725-.2659-.38673-.2801-.61878-.0142-.23206.0578-.46137.2028-.64651.6286-.80191 1.6569-2.11458 2.093-2.67692.596-.76862 1.6748-2.39693.1958-2.39693Z"
        fill="#fff"
      />
    </svg>
  </i>
);
