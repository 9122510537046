import React from 'react';

import styles from './auth-page-header.module.css';
import logo from '../../assets/logo.svg';
import { useAppStore } from '../../store/app';
import { QH } from '../../constants';

export const AuthPageHeader = () => {
  const { gameData } = useAppStore();
  const gameName = gameData?.name;
  return (
    <div className={styles.container}>
      <img className={styles.logo} src={logo} alt={QH} />
      {gameName && <div className={styles.gameName}>{gameName}</div>}
    </div>
  );
};
