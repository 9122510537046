import React, { useEffect, useState } from 'react';

import { Spinner } from '../../ui/spinner/spinner';
import classes from './page-spinner.module.css';

export const PageSpinner = ({ debounceTimeout = 150 }) => {
  const hasTimeout = debounceTimeout > 0;
  const [showLoader, setShowLoader] = useState(!hasTimeout);

  useEffect(() => {
    const timer = hasTimeout
      ? setTimeout(() => setShowLoader(true), debounceTimeout)
      : undefined;
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  if (!showLoader) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Spinner />
    </div>
  );
};
