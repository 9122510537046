import React, { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

import { HelmetProvider } from 'react-helmet-async';

import { App } from './app';
import { LayoutStub } from './components/layout-stub/layout-stub';
import { sentryConfig } from './configs/sentry/sentry-config';
import { isDev } from './constants';

if (!isDev()) {
  Sentry.init(sentryConfig);
}

const container = document.getElementById('app');

if (!container) {
  throw new Error(
    'Can not find app container. It seems you forget add to html <div id="app" />'
  );
}

const root = createRoot(container);
root.render(
  <StrictMode>
    <Suspense fallback={<LayoutStub />}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </Suspense>
  </StrictMode>
);
