import React, { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { Root } from '../pages/root';
import { ErrorBoundary } from '../components/error-boundary/error-boundary';
import { NotFound } from '../pages/notfound/notfound';

const SignUp = lazy(() => import('../pages/sign-up/sign-up'));

export const router = createBrowserRouter([
  {
    path: '/',
    ErrorBoundary,
    children: [
      { index: true, element: <Root /> },
      {
        path: 'reg',
        element: <SignUp />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);
