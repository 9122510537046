export const TEST_TOKEN = 'test';
export const TEST_TOKEN_GUEST = 'guest_test';

export const QH = 'QuizHeroes';
export const TELEGRAM_LINK = 'https://t.me/PlagueGame';
export const TELEGRAM_SUPPORT_LINK = 'https://t.me/QuizHeroesSupport';
export const VK_LINK = 'https://vk.com/quizheroes';
export const INSTAGRAM_LINK = 'https://www.instagram.com/quizheroes';

export const DEFAULT_LOCALE = 'ru';

export const NAME_MAX_LENGTH = 100;

export const API_STATES = {
  ERROR: 'error',
  SUCCESS: 'success',
};

export const isDev = () => process.env.NODE_ENV === 'development';

export const CONFIG_FILE = process.env.CONFIG;

export const isTestEnv = () => window.location.host.split('.')[0] === 'beta';
