import React, { FC, forwardRef } from 'react';
import cn from 'classnames';
import { camelCase } from 'lodash';

import styles from './button.module.css';

type ButtonAlign = 'start' | 'center';

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  type?: 'submit' | 'reset' | 'button';
  variant?: 'primary' | 'secondary' | 'link' | 'answer' | 'empty';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  block?: boolean;
  active?: boolean;
  icon?: React.ReactNode;
  iconPosition?: 'start' | 'end';
  ellipsis?: boolean;
  align?: 'start' | 'center';
};

export const Button: FC<ButtonProps> = forwardRef(
  (
    {
      type = 'button',
      className,
      children,
      variant = 'default',
      onClick,
      block,
      active,
      icon,
      iconPosition = 'start',
      ellipsis,
      align: alignRaw,
      ...rest
    },
    ref
  ) => {
    const defaultAlign: ButtonAlign = ['secondary', 'primary'].includes(variant)
      ? 'center'
      : 'start';
    const align = alignRaw || defaultAlign;
    const withoutChildren = !children;
    return (
      <button
        className={cn(
          styles.button,
          styles[variant],
          block && styles.block,
          active && styles.active,
          className
        )}
        type={type}
        onClick={onClick}
        ref={ref}
        {...rest}
      >
        {icon && iconPosition === 'start' && (
          <span
            className={cn(
              styles.icon,
              styles.start,
              withoutChildren && styles.withoutChildren
            )}
          >
            {icon}
          </span>
        )}
        {children && (
          <span
            className={cn(
              styles.text,
              styles[camelCase(`align-${align}`)],
              ellipsis && styles.textEllipsis,
              iconPosition === 'end' && styles.withIconAtEnd
            )}
          >
            {children}
          </span>
        )}
        {icon && iconPosition === 'end' && (
          <span
            className={cn(
              styles.icon,
              styles.end,
              withoutChildren && styles.withoutChildren
            )}
          >
            {icon}
          </span>
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';
