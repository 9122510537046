import { getStorageItem } from './storage';

export const LANGUAGE_KEY = '_quiz-heroes-language';

const getNavigatorLanguage = () => {
  // if (navigator.languages && navigator.languages.length) {
  //   return navigator.languages[0];
  // }
  return navigator.languages?.[0] || navigator.language || 'ru';
};

export const getDefaultLanguage = () =>
  getStorageItem(LANGUAGE_KEY) || getNavigatorLanguage();
